import Vue from 'vue'
import Vuex from 'vuex'
import RestService from '../services/RestService'
import * as pageBuilder from '@/plugins/page-builder/store'
import * as notification from '@/plugins/notifications/store'
import _ from 'lodash'
import i18n from '@/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		version: '2.1.2',
		setupLoading: false,
		language: 'de',
		languages: [
			{ ID: 'de', name: 'German' },
			{ ID: 'en', name: 'English' },
		],
		scanner: false,
		gallery: false,
		guest: { ID: 0 },
	},
	mutations: {
		setLanguage(state, lang) {
			state.language = lang
			i18n.locale = lang
		},
	},
	actions: {
		loadData({ dispatch, state }, params) {
			return RestService.get('/' + params.join('/'))
				.then((response) => {
					_.each(response.data, (value, key) => {
						state[key] = value
					})
					return true
				})
				.catch((error) => {
					state.setupLoading = false
					dispatch(
						'notification/add',
						{
							type: 'error',
							message: error.response.data.message || error.message,
							duration: 5,
						},
						{ root: true }
					)
					return false
				})
		},
		appointment(_, data) {
			return RestService.post('/form/appointment', data)
				.then(() => {
					return true
				})
				.catch(() => {
					return false
				})
		},
		setStatus({ dispatch, state }, params) {
			return RestService.post(params.route, params.data)
				.then(() => {
					return true
				})
				.catch((error) => {
					state.setupLoading = false
					dispatch(
						'notification/add',
						{
							type: 'error',
							message: error.response.data.message || error.message,
							duration: 5,
						},
						{ root: true }
					)
					return false
				})
		},
	},
	getters: {},
	modules: {
		notification,
		pageBuilder,
	},
})
