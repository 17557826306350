import RestService from '@/services/RestService'
import _ from 'lodash'

export const namespaced = true

export const state = {
	googleMapsApiKey: 'AIzaSyB1ntPk96Q8crTqk7zSPnKgRtHACpwEZE8',
	page: {},
	structure: [],
	contents: [],
	menu: [],
	guest: [],
	settings: {},
}

export const mutations = {
	//
}

export const actions = {
	loadPage({ dispatch, state }, params) {
		return RestService.get(`/page/contents?lang=${params.language}&route=${params.route}&token=${params.token}`)
			.then((response) => {
				if (response.data.redirect) {
					return response.data.redirect
				}
				_.each(response.data, (value, key) => {
					state[key] = value
				})
				return true
			})
			.catch((error) => {
				state.setupLoading = false
				if (error.response.status === 404) return false

				dispatch(
					'notification/add',
					{
						type: 'error',
						message: error.response.data.message || error.message,
						duration: 5,
					},
					{ root: true }
				)
				return false
			})
	},
}
