import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/Error404.vue'),
		props: true,
		beforeEnter(routeTo, routeFrom, next) {
			store.state.setupLoading = false
			next()
		},
	},
	{
		path: '/vernissage/:id',
		name: 'Vernissage',
		props: true,
		component: () => import('../views/Vernissage'),
	},
	{
		path: '/en/:path(.*)?',
		name: 'WebsiteDE',
		component: () => import('@/plugins/page-builder/views/PageBuilder'),
		props: true,
		beforeEnter(routeTo, routeFrom, next) {
			store.commit('setLanguage', 'en')
			store
				.dispatch('pageBuilder/loadPage', {
					language: 'en',
					route: '/' + (routeTo.params.path || ''),
					token: routeTo.query.token || '',
				})
				.then((done) => {
					if (done === true) next()
					else if (!done) next('/404')
					else next(done)
				})
		},
	},
	{
		path: '/:path(.*)?',
		name: 'WebsiteEN',
		component: () => import('@/plugins/page-builder/views/PageBuilder'),
		props: true,
		beforeEnter(routeTo, routeFrom, next) {
			store.commit('setLanguage', 'de')
			store
				.dispatch('pageBuilder/loadPage', {
					language: 'de',
					route: '/' + (routeTo.params.path || ''),
					token: routeTo.query.token || '',
				})
				.then((done) => {
					if (done === true) next()
					else if (!done) next('/404')
					else next(done)
				})
		},
	},
]

const router = new VueRouter({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes,
})

export default router
