import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#EE2375',
				secondary: '#0083BF',
				greyOne: '#434549',
				greyTwo: '#5e5d61',
				greyTree: '#8f8c8f',
				greyFour: '#b4b1b3',
				greyFive: '#dfdedf',
				brainlabPurple: '#7F3F98',
				brainlabGreen: '#00A886',
				brainlabOrange: '#FAA21B',
			},
		},
	},
})
